import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { atHomeStore, mainStore } from '@/store';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.loading = true;
        this.items = [];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    handleLanguage(item) {
        this.$router.push({
            name: 'at-home-details',
            params: { id: item.id },
        });
    }
    async mounted() {
        this.loading = true;
        this.items = await atHomeStore.getAtHomeTurmas(true);
        this.loading = false;
    }
};
List = __decorate([
    Component({})
], List);
export default List;
